/* eslint-disable max-lines */
/* esline-disable max-lines */
import type { Theme, ThemeUIStyleObject } from "theme-ui";

const heading = {
	color: "text",
	fontSize: [3, 4, 5],
	fontFamily: "heading",
	lineHeight: "heading",
	fontWeight: "heading",
	textShadow: "heading",
};

export interface EcogardenTheme extends Theme {
	readonly badges: {
		readonly accent: ThemeUIStyleObject;
	};

	readonly buttons: {
		readonly default: ThemeUIStyleObject & { readonly gridGap?: string };
		readonly primary: ThemeUIStyleObject & { readonly gridGap?: string };
		// Could extend the theme properly and not need to apply these
		readonly icon: ThemeUIStyleObject;
		readonly simple: ThemeUIStyleObject;
		readonly leaf: ThemeUIStyleObject;
		readonly alert: ThemeUIStyleObject;
	};

	readonly alerts: {
		readonly primary: ThemeUIStyleObject;
	};

	readonly links: {
		readonly nav: ThemeUIStyleObject;
	};

	readonly forms: {
		readonly input: ThemeUIStyleObject;
		readonly mono: ThemeUIStyleObject;
		readonly slider: ThemeUIStyleObject;
		readonly label?: ThemeUIStyleObject;
	};

	readonly cards: {
		readonly primary: ThemeUIStyleObject;
	};
}

const theme: EcogardenTheme = {
	useLocalStorage: false,
	colors: {
		text: "black",
		background: "var(--bg-color)",
		primary: "var(--accent-fg-color)",
		accent: "#00734f",
		"accent-bg": "var(--accent-bg-color)",
		"accent-bg-90": "var(--accent-bg-color-90)",
		"accent-bg-offset": "var(--accent-bg-color-offset)",
		highlight: "#e1c28b",
		muted: "#d3b786",
		alert: "#a70f25",
		modes: {
			dark: {
				text: "#fff",
				background: "#0c0a11",
				accent: "#4cae76",
			},
			light: {
				text: "var(--fg-color)",
				background: "var(--bg-color)",
				primary: "var(--accent-fg-color)",
				accent: "#49b996",
				"accent-bg": "var(--accent-bg-color)",
				"accent-bg-90": "var(--accent-bg-color-90)",
				"accent-bg-offset": "var(--accent-bg-color-offset)",
			},
		},
	},
	space: [
		"0em",
		".25em",
		"0.4rem",
		"1rem",
		"2em",
		"4em",
		"8em",
		"16em",
		"32em",
	],
	fonts: {
		body: "-apple-system, BlinkMacSystemFont, var(--font-family-serif), var(--default-fonts-serif), var(--emoji-fonts)",
		heading:
			"-apple-system, BlinkMacSystemFont, var(--font-family-sans-serif), var(--default-fonts-sans-serif), var(--emoji-fonts)",
		button:
			"-apple-system, BlinkMacSystemFont, var(--font-family-sans-serif), var(--default-fonts-sans-serif), var(--emoji-fonts)",
		monospace: "Menlo, monospace",
	},
	fontSizes: [
		"0.75em",
		"0.90em",
		"1em",
		"1.21em",
		"1.43em",
		"1.63em",
		"1.8em",
		"2em",
		"2.1em",
	],
	fontWeights: {
		body: 400,
		heading: 650,
		bold: 700,
	},
	shadows: {
		heading: "1px 1px 0 var(--theme-ui-colors-background)",
		button:
			"0 0 0 2px var(--theme-ui-colors-accent), 1px 1px 0 3px var(--theme-ui-colors-background)",
		glow: "0 0 0 2px var(--theme-ui-colors-accent), 0 0 3px 5px #ffd700e0",
		alert:
			"0 0 0 2px var(--theme-ui-colors-alert), 0 0 3px 5px var(--theme-ui-colors-alert)",
		"glow-alert":
			"0 0 0 2px var(--theme-ui-colors-accent), 0 0 3px 5px var(--theme-ui-colors-accent)",
		panel: "2pt 2pt 0 var(--theme-ui-colors-accent-bg)",
		primary:
			"1px 1px 0px var(--theme-ui-colors-background)," +
			"1px 1px 4px var(--theme-ui-colors-background)," +
			"-1px -1px 12px var(--theme-ui-colors-background)," +
			"0 0 16px var(--theme-ui-colors-background)",
	},
	radii: {
		rounded: "4px",
		circle: "50%",
		primary: "0 .8rem",
		panelTop: "0 0 0 0.8rem",
		panelBottom: "0 0.8rem 0 0",
		leaf: "0 50%",
	},
	lineHeights: {
		body: 1.4,
		heading: 1.4,
	},
	borderWidths: {
		small: "1px",
		medium: "2px",
		thick: "4px",
	},
	borders: {
		panel: "2pt solid var(--theme-ui-colors-accent)",
	},
	buttons: {
		primary: {
			color: "text",
			backgroundColor: "primary",
			backgroundImage:
				"linear-gradient(0deg, var(--theme-ui-colors-accent), var(--theme-ui-colors-primary))",
			boxShadow: "button",
			textShadow: "primary",
			borderRadius: "rounded",
			cursor: "pointer",
			fontWeight: "bold",
			fontFamily: "button",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			gap: [1, 2],
			"&:hover,&:focus": {
				borderRadius: 0,
				boxShadow: "glow",
				backgroundColor: "#ffd600",
			},
			"&:active": {
				transform: "translate3d(1pt, 1pt, 1)",
			},
		},
		default: {
			color: "text",
			backgroundColor: "background",
			boxShadow: "button",
			borderRadius: "rounded",
			fontFamily: "button",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			gap: [1, 2],
			cursor: "pointer",
			"&:hover,&:focus": {
				borderRadius: 0,
				boxShadow: "glow",
			},
			"&:active": {
				transform: "translate3d(1pt, 1pt, 0)",
			},
		},
		icon: {
			color: "inherit",
			backgroundColor: "accent-bg",
			backgroundImage:
				"linear-gradient(0deg, var(--theme-ui-colors-accent-bg-offset), var(--theme-ui-colors-accent-bg))",
			boxShadow: "none",
			borderStyle: "solid",
			borderWidth: "medium",
			borderColor: "transparent",
			cursor: "pointer",
			fontFamily: "button",
			transition: "all  96ms ease-in-out",
			transform: "none",
			width: 44,
			height: 42,
			padding: 0,

			"&:hover,&:focus": {
				borderRadius: "circle",
				borderColor: "accent-bg",
				backgroundColor: "accent-bg",
				boxShadow: "glow",
			},
			"&:active": {
				transform: "translate3d(1pt, 1pt, 0)",
			},
		},
		simple: {
			color: "inherit",
			boxShadow: "none",
			cursor: "pointer",
			fontFamily: "button",
			transition: "all  96ms ease-in-out",
			transform: "none",
			minWidth: 44,
			minHeight: 42,
			padding: 0,
			backgroundColor: "accent-bg",

			"&:hover,&:focus": {
				borderColor: "accent-bg",
				backgroundColor: "accent-bg",
				boxShadow: "glow",
				opacity: 1,
			},
			"&:active": {
				transform: "translate3d(1pt, 1pt, 0)",
			},
		},
		leaf: {
			backgroundColor: "accent-bg",
			backgroundImage:
				"linear-gradient(0deg, var(--theme-ui-colors-accent-bg-offset), var(--theme-ui-colors-accent-bg))",
			boxShadow: "button",
			borderStyle: "solid",
			borderWidth: "small",
			borderColor: "accent",
			borderRadius: "primary",
			cursor: "pointer",
			transition: "all 240ms ease-in-out",
			transform: "none",
			"&:hover,&:focus": {
				borderRadius: "leaf",
				backgroundColor: "green",
				borderColor: "accent",
				boxShadow: "glow",
				color: "white",
			},
			":active": {
				transform: "translate3d(1pt, 1pt, 0)",
			},
		},
		alert: {
			backgroundColor: "alert",
			boxShadow: "button",
			textShadow: "heading",
			borderRadius: "rounded",
			fontFamily: "button",
			cursor: "pointer",
			"&:hover,&:focus": {
				borderRadius: 0,
				boxShadow: "glow-alert",
			},
			"&:active": {
				transform: "translate3d(1pt, 1pt, 1)",
			},
		},
	},
	text: {
		heading: {
			fontSize: [2, 3],
			lineHeight: 1.4,
			marginY: [1, 2],
		},
	},
	badges: {
		accent: {
			color: "text",
			bg: "accent-bg",
		},
	},
	alerts: {
		primary: {
			color: "text",
			bg: "alert",
		},
	},
	links: {
		nav: {
			px: 2,
			py: 1,
			"&:hover": {
				color: "text",
			},
		},
	},

	cards: {
		primary: {
			padding: 2,
			borderRadius: 4,
			border: "1px solid",
			borderColor: "var(--accent-fg-color)",
			boxShadow: "0 0 4px var(--accent-bg-color)",
		},
	},
	forms: {
		slider: {
			color: "text",
			bg: "accent-bg",
			height: ["42px"],
			"&:hover,&:focus": {
				color: "text",
				bg: "accent-bg",
				boxShadow: "glow",
			},
		},
		input: {
			borderRadius: 0,
			lineHeight: "initial",
			borderColor: "accent",
			borderWidth: "medium",
			"&:focus,&:hover": {
				borderColor: "gold",
			},
			"&:focus-within": {
				outline: "2px solid var(--accent-fg-color)",
			},
		},
		mono: {
			fontFamily: "monospace",
			borderRadius: 0,
			lineHeight: "initial",
			borderColor: "accent",
			borderWidth: "medium",
			"&:focus,&:hover": {
				borderColor: "gold",
				boxShadow: "glow",
			},
		},
		label: {
			"&:focus,&:hover": {
				backgroundColor: "accent-bg-90",
			},
		},
	},
	styles: {
		root: {
			fontFamily: "body",
			lineHeight: "body",
			fontWeight: "body",
			fontSize: [1, 2],
		},
		h1: {
			...heading,
			fontSize: [5, 6, 7],
		},
		h2: {
			...heading,
			fontSize: [4, 5],
		},
		h3: {
			...heading,
			fontSize: [2, 3, 4],
		},
		h4: {
			...heading,
			fontSize: [2, 3],
		},
		h5: {
			...heading,
			fontSize: [1, 2],
		},
		h6: {
			...heading,
			fontSize: [1, 1],
		},
		p: {
			color: "text",
			fontFamily: "body",
			fontWeight: "body",
			lineHeight: "body",
			fontSize: [2, 3],
		},
		a: {
			color: "accent",
		},
		pre: {
			fontFamily: "monospace",
			overflowX: "auto",
			backgroundColor: "accent-bg",
			code: {
				color: "inherit",
			},
		},
		code: {
			fontFamily: "monospace",
			fontSize: "inherit",
			backgroundColor: "accent-bg",
		},
		table: {
			width: "100%",
			borderCollapse: "separate",
			borderSpacing: 0,
		},
		th: {
			textAlign: "left",
			borderBottomStyle: "solid",
		},
		td: {
			textAlign: "left",
			borderBottomStyle: "solid",
		},
		img: {
			maxWidth: "100%",
		},
	},
};

export default theme;
