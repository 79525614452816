/* eslint-disable max-lines */
/**
 * Available shapes
 */
export type Shape =
  | "perennial"
  | "grass"
  | "shrub"
  | "evergreen-shrub"
  | "small-tree"
  | "fruit-tree"
  | "deciduous-tree"
  | "evergreen-tree"
  | "concrete"
  | "patio"
  // | "flower-bed"
  | "deck"
  | "asphalt"
  | "gravel"
  | "flagstone"
  | "lawn"
  | "mulch"
  | "leaves"
  | "bushy"
  | "house"
  | "chair"
  | "bench"
  | "table"
  | "car"
  | "dining-table"
  | "label"
  | "label-draw"
  | "site-plan-draw"
  | "line"
  | "image";

export const SHAPES = Object.freeze(
  ((): readonly Shape[] => {
    const hardscapes: readonly Shape[] = [
      "concrete",
      "patio",
      "deck",
      "asphalt",
      "gravel",
      "flagstone",
    ];

    const plants: readonly Shape[] = [
      "perennial",
      "grass",
      "shrub",
      "evergreen-shrub",
      "small-tree",
      // 'fruit-tree',
      "deciduous-tree",
      "evergreen-tree",
    ];

    const groundcover: readonly Shape[] = ["lawn", "mulch", "bushy", "leaves"];
    const structures: readonly Shape[] = [
      "house",
      "chair",
      "bench",
      "table",
      "dining-table",
    ];

    const notes: readonly Shape[] = ["label", "label-draw", "line"];

    const sitePlan: readonly Shape[] = ["site-plan-draw"];

    const images: readonly Shape[] = ["image"];

    const objects: readonly Shape[] = ["car"];

    return [
      ...plants,
      ...groundcover,
      ...hardscapes,
      ...structures,
      ...notes,
      ...sitePlan,
      ...images,
      ...objects,
    ];
  })()
);

type Tool = "Polygon" | "Shape" | "TextBox" | "Freedraw" | "Line" | "Image";

type SVGShape = "rect" | "circle" | "polygon" | "ellipse" | "i-text" | "path";

type ShapeDetail = {
  /**
   * Tools that this shape can handle
   */
  readonly tool: readonly Tool[];

  /**
   * SVG Shape type for this shape to navigate how to interact with the shape.
   */
  readonly shape?: SVGShape[] | SVGShape;

  /**
   * Layer the shape is found on
   */
  readonly layer: Layer;

  /**
   * Default size of the object. More used than width/height currently. Might change.
   */
  readonly size?: number;

  /**
   * Depth of the shape (relative to height but more realistic in relative terms)
   */
  readonly depth?: number;

  /**
   * Default width of the shape in pixels
   */
  readonly width?: number;

  /**
   * Default height of the shape in pixels
   */
  readonly height?: number;

  /**
   * Sets the priority of the shape on the layer
   * Ex: for sorting
   */
  readonly layerPriority?: number;

  /**
   * Minimum size in feet
   */
  readonly minSize?: number;

  /**
   * Maximum size in feet
   */
  readonly maxSize?: number;

  /**
   * minimum width in feet
   */
  readonly minWidth?: number;

  /**
   * maxmimum width in feet
   */
  readonly maxWidth?: number;

  /**
   * minimum height in feet
   */
  readonly minHeight?: number;

  /**
   * maxmimum height in feet
   */
  readonly maxHeight?: number;

  /**
   * minimum depth in feet (in 2d we represent this as height)
   */
  readonly minDepth?: number;

  /**
   * maximum depth in feet (in 2d we represent this as height)
   */
  readonly maxDepth?: number;
};

export type ShapeDetails = ReadonlyMap<string, ShapeDetail>;

export const SHAPE_DETAILS: ShapeDetails = Object.freeze(
  new Map<Shape, ShapeDetail>([
    ["concrete", { tool: ["Polygon"], layer: "hardSurfaces" }],
    ["asphalt", { tool: ["Polygon"], layer: "hardSurfaces" }],
    ["patio", { tool: ["Polygon"], layer: "hardSurfaces" }],
    ["gravel", { tool: ["Polygon"], layer: "hardSurfaces" }],
    ["flagstone", { tool: ["Polygon"], layer: "hardSurfaces" }],
    [
      "perennial",
      {
        tool: ["Shape"],
        layer: "herbaceous",
        shape: ["circle"],
        size: 50,
        width: 50,
        height: 50,
        minSize: 0.5,
        maxSize: 6,
      },
    ],
    [
      "grass",
      {
        tool: ["Shape"],
        layer: "herbaceous",
        shape: ["circle"],
        size: 50,
        width: 50,
        height: 50,
        minSize: 0.5,
        maxSize: 6,
      },
    ],
    [
      "shrub",
      {
        tool: ["Shape"],
        layer: "shrubs",
        shape: ["circle"],
        size: 150,
        width: 150,
        height: 150,
        minSize: 0.5,
        maxSize: 15,
      },
    ],
    [
      "evergreen-shrub",
      {
        tool: ["Shape"],
        layer: "shrubs",
        shape: ["circle"],
        size: 150,
        width: 150,
        height: 150,
        minSize: 0.5,
        maxSize: 15,
      },
    ],
    [
      "small-tree",
      {
        tool: ["Shape"],
        layer: "smallTrees",
        shape: ["circle"],
        size: 150,
        width: 150,
        height: 150,
        minSize: 3,
        maxSize: 25,
      },
    ],
    [
      "fruit-tree",
      {
        tool: ["Shape"],
        layer: "smallTrees",
        shape: ["circle"],
        size: 150,
        width: 150,
        height: 150,
        minSize: 3,
        maxSize: 25,
      },
    ],
    [
      "deciduous-tree",
      {
        tool: ["Shape"],
        layer: "trees",
        shape: ["circle"],
        size: 300,
        width: 300,
        height: 300,
        minSize: 3,
        maxSize: 100,
      },
    ],
    [
      "evergreen-tree",
      {
        tool: ["Shape"],
        layer: "trees",
        shape: ["circle"],
        size: 300,
        depth: 300,
        width: 300,
        height: 300,
        minSize: 3,
        maxSize: 100,
      },
    ],
    ["lawn", { tool: ["Polygon"], layer: "surfaces" }],
    ["leaves", { tool: ["Polygon"], layer: "surfaces", layerPriority: 2 }],
    ["mulch", { tool: ["Polygon"], layer: "surfaces", layerPriority: 2 }],
    ["bushy", { tool: ["Polygon"], layer: "surfaces", layerPriority: 2 }],
    ["deck", { tool: ["Polygon"], layer: "hardSurfaces" }],
    [
      "house",
      {
        tool: ["Shape"],
        layer: "structures",
        shape: ["rect"],
        width: 1000,
        height: 500,
        depth: 500,
        size: 1000,
        minWidth: 3,
        maxWidth: 100,
        minDepth: 3,
        maxDepth: 100,
      },
    ],
    [
      "chair",
      {
        tool: ["Shape"],
        layer: "structures",
        shape: ["rect"],
        width: 41.667,
        height: 41.667,
        depth: (25 / 12) * 20,
        minSize: 1.5,
        maxSize: 4,
        size: (25 / 12) * 20, // 20 inches
        minWidth: 0.5,
        maxWidth: 4,
        minDepth: 0.5,
        maxDepth: 4,
      },
    ],
    [
      "bench",
      {
        tool: ["Shape"],
        layer: "structures",
        shape: ["rect"],
        size: 125,
        depth: 37.5,
        width: 125,
        height: 37.5,
        minWidth: 3,
        maxWidth: 12,
        minDepth: 1,
        maxDepth: 3,
      },
    ],
    [
      "table",
      {
        tool: ["Shape"],
        layer: "structures",
        shape: ["circle"],
        width: 75,
        height: 75,
        size: 75,
        depth: 75,
        minSize: 1.5,
        maxSize: 20,
      },
    ],
    [
      "dining-table",
      {
        tool: ["Shape"],
        layer: "structures",
        shape: ["rect"],
        size: 300,
        width: 300,
        height: 137.5,
        depth: 137.5,
        minWidth: 3,
        maxWidth: 12,
        minDepth: 3,
        maxDepth: 6,
      },
    ],
    [
      "label",
      {
        tool: ["TextBox"],
        layer: "labels",
        shape: ["rect"],
        layerPriority: 2,
      },
    ],
    [
      "label-draw",
      {
        tool: ["Freedraw"],
        layer: "labels",
        shape: ["rect"],
      },
    ],
    [
      "site-plan-draw",
      {
        tool: ["Freedraw"],
        layer: "sitePlan",
        layerPriority: 2,
        // shape: ["rect"],
      },
    ],
    ["line", { tool: ["Line"], layer: "labels", shape: ["rect"] }],
    [
      "image",
      {
        tool: ["Image"],
        layer: "sitePlan",
        shape: ["rect"],

        minSize: 1.5,
        maxSize: 2000,
        maxWidth: 2000,
      },
    ],
    ["car", { tool: ["Shape"], layer: "structures", shape: ["rect"] }],
  ])
);

/**
 * Returns the sizes that are available to be user adjusted.
 * Ex: Changing width/height sizes
 */
export const getAvailableSizes = (
  subtype: Shape | undefined
): readonly ("size" | "width" | "depth")[] => {
  if (subtype === "label") {
    return [];
  }

  const shapeDetail = findShapeDetail(subtype)?.shape;

  if (shapeDetail?.includes("rect")) {
    return ["width", "depth"];
  }

  return ["size"];
};

// SHAPES
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

/**
 * Find the shape definition for a type/subtype.
 * This works well with unknown values that could be undefined.
 * Common in the fabric object interface.
 *
 * ## Example
 *		findShape('perennial');
 */
export const findShape = (shape?: Shape): ShapeDetail | undefined => {
  if (!shape) {
    return undefined;
  }

  return SHAPE_DETAILS.get(shape);
};

/**
 * Get the shape details
 */
export const findShapeDetail = (
  shape: Shape | string | undefined
): ShapeDetail | undefined => {
  if (!shape) {
    return undefined;
  }

  return SHAPE_DETAILS.get(shape);
};

export const findDefaultWidth = (
  shape: Shape | undefined
): number | undefined => {
  if (!shape) {
    return undefined;
  }
  return findShapeDetail(shape)?.width;
};

export const findDefaultHeight = (
  shape: Shape | undefined
): number | undefined => {
  if (!shape) {
    return undefined;
  }
  return findShapeDetail(shape)?.height;
};

/**
 * Get the tool to render the shape
 */
export const findShapeTools = (
  shapeName: Shape | string | undefined
): readonly Tool[] => {
  if (!shapeName) {
    return [];
  }

  const shape = SHAPE_DETAILS.get(shapeName);

  if (!shape) {
    return [];
  }

  return shape.tool;
};

/**
 * Get the default size of a shape.
 * Polygon shapes will come up undefined
 */
export const findDefaultPixelSize = (
  shapeName: Shape | string | undefined
): number | undefined => {
  const shapeDetail = findShapeDetail(shapeName);

  if (!shapeDetail) {
    return undefined;
  }

  if ("size" in shapeDetail && shapeDetail.size != undefined) {
    return shapeDetail.size;
  }

  return undefined;
};

export const getMinSize =
  (shape: Shape | undefined) =>
  (type: string): number => {
    const shapeDetail = findShapeDetail(shape);

    if (!shapeDetail) {
      return 1;
    }
    if (type === "depth") {
      return getMinDepth(shape);
    }

    if (shapeDetail.shape?.includes("rect")) {
      return shapeDetail.minWidth ?? shapeDetail.minSize ?? 1;
    }

    return shapeDetail.minSize ?? 1;
  };

export const getMaxSize =
  (shape: Shape | undefined) =>
  (type: string): number => {
    const shapeDetail = findShapeDetail(shape);

    if (!shapeDetail) {
      return 100;
    }
    if (type === "depth") {
      return getMaxDepth(shape);
    }

    if (shapeDetail.shape?.includes("rect")) {
      return shapeDetail.maxWidth ?? shapeDetail.maxSize ?? 100;
    }

    return shapeDetail.maxSize ?? 100;
  };

export const getMaxDepth = (shape: Shape | undefined): number => {
  const shapeDetail = findShapeDetail(shape);

  if (!shapeDetail) {
    return 100;
  }

  if (shapeDetail.shape?.includes("rect")) {
    return shapeDetail.maxDepth ?? 100;
  }

  return shapeDetail.maxSize ?? 100;
};

export const getMinDepth = (shape: Shape | undefined): number => {
  const shapeDetail = findShapeDetail(shape);

  if (!shapeDetail) {
    return 1;
  }

  if (shapeDetail.shape?.includes("rect")) {
    return shapeDetail.minDepth ?? 1;
  }

  return shapeDetail.minSize ?? 1;
};

export const getMaximumSize =
  (type: string | undefined) =>
  (shape: Shape | undefined): number => {
    if (!shape) {
      return 100;
    }
    const shapeDetail = findShapeDetail(shape);

    if (!shapeDetail) {
      return 100;
    }

    if (shapeDetail.shape?.includes("rect")) {
      return shapeDetail.maxWidth ?? 100;
    }

    return shapeDetail.maxSize ?? 100;
  };

export const getMinimumSize =
  (type: string | undefined) => (shape: Shape | undefined) => {
    if (!shape) {
      return 0.5;
    }

    const shapeDetail = findShapeDetail(shape);

    if (!shapeDetail) {
      return 0.5;
    }

    if (shapeDetail.shape?.includes("rect")) {
      return shapeDetail.minWidth ?? 0.5;
    }

    return shapeDetail.minSize ?? 0.5;
  };

export const fitIntoSize =
  (min: number) =>
  (max: number) =>
  (value: number): number =>
    value < min ? min : value > max ? max : value;

/**
 * Compose scale options from name ((scaleX, scaleY)
 */
export const composeScale =
  (name: "size" | "width" | "depth") =>
  (scale: number): { readonly scaleX?: number; readonly scaleY?: number } => {
    if (name === "size") {
      return { scaleX: scale, scaleY: scale };
    }

    if (name === "width") {
      return { scaleX: scale };
    }

    if (name === "depth") {
      return { scaleY: scale };
    }

    return {};
  };

/**
 * Is a custom shape (polygon, rectangle, circle)
 * This shape allows for fills instead of SVG source
 */
export const isCustomShape = (shape: Shape | undefined): boolean => {
  if (!shape) {
    return false;
  }

  const detail = SHAPE_DETAILS.get(shape);

  if (!detail) {
    return false;
  }

  return detail.tool.includes("Polygon");
};

export const isLineTool = (shape: Shape | undefined): boolean => {
  if (!shape) {
    return false;
  }

  const detail = SHAPE_DETAILS.get(shape);

  if (!detail) {
    return false;
  }

  return detail.tool.includes("Line");
};

// Move out or rework naming
export const isTextbox = (shape: Shape | undefined): boolean => {
  return shape === "label";
};

// Move out or rework naming
export const isLine = (shape: Shape | undefined): boolean => {
  return shape === "line";
};

export const isPath = (shape: Shape | undefined): boolean => {
  return shape === "label-draw" || shape === "site-plan-draw";
};

export const isImage = (shape: Shape | undefined): boolean => {
  return shape === "image";
};

export const isCircle = (shape: Shape | undefined): boolean => {
  if (!shape) {
    return false;
  }
  return findShapeDetail(shape)?.shape?.includes("circle") ?? false;
};

/**
 * Get fill path
 */
export const getFillFile = (shape: string): string =>
  `/assets/img/objects/${shape}.png`;

/**
 * SVG file path
 */
export const getShapeSVGFile = (shape: Shape): string =>
  `/assets/svg/objects/${shape.toLowerCase()}.svg`;

export const getMainAssetFile = (shape: Shape): string => {
  if (isCustomShape(shape)) {
    return getFillFile(shape);
  }

  const shapeDetail = findShapeDetail(shape);

  if (shapeDetail?.tool.includes("TextBox")) {
    return "/assets/svg/textbox.svg";
  }

  return getShapeSVGFile(shape);
};

// LAYERS
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=

/**
 * Layer key
 */
export type Layer =
  | "sitePlan"
  | "surfaces"
  | "hardSurfaces"
  | "herbaceous"
  | "shrubs"
  | "smallTrees"
  | "structures"
  | "trees"
  | "labels";

export type LayerOption = {
  readonly index: number;
  readonly lock?: boolean;
  readonly name?: string;
  readonly visible?: boolean;
};

export type Layers = { readonly [K in Layer]: LayerOption };

/**
 * Default LAYERS state
 */
export const LAYERS: Layers = Object.freeze({
  sitePlan: { index: 1, name: "Site Plan", lock: true, visible: true },
  surfaces: { index: 2, name: "Surfaces", lock: true, visible: true },
  hardSurfaces: { index: 6, name: "Hard Surfaces", lock: true, visible: true },
  herbaceous: { index: 12, name: "Herbaceous", lock: false, visible: true },
  shrubs: { index: 24, name: "Shrubs", lock: false, visible: true },
  smallTrees: { index: 32, name: "Small Trees", lock: false, visible: true },
  structures: { index: 38, name: "Structures", lock: false, visible: true },
  trees: { index: 44, name: "Trees", lock: false, visible: true },
  labels: { index: 66, name: "Labels", lock: true, visible: true },
});

/**
 * Give static context to explain layers
 */
export const LAYERS_CONTEXT: {
  readonly [K in Layer]: { readonly description: string };
} = {
  sitePlan: { description: "The base of your design." },
  surfaces: { description: "Lawn, mulch, rocks." },
  hardSurfaces: { description: "Driveways, sidewalks, patio, decks." },
  herbaceous: { description: "Perennials, ferns, annuals." },
  shrubs: { description: "Shrubs." },
  smallTrees: { description: "Small, understory, fruit trees. 25 feet" },
  structures: { description: "House, garage, shed, gazebo." },
  trees: { description: "Large, shade trees." },
  labels: { description: "Labels on your landscape." },
};

/**
 * Find the layer index for a layer
 */
export const getLayerIndex = (layer: Layer): number => LAYERS[layer].index;

/**
 * Find the layer for a shape.
 */
export const findShapeLayer = (
  shapeName: Shape | string | undefined
): Layer | undefined => {
  if (!shapeName) {
    return undefined;
  }

  return SHAPE_DETAILS.get(shapeName)?.layer;
};

/**
 * Find the layer index of a shape.
 */
export const findShapeLayerIndex = (
  shapeName: Shape | string | undefined
): number | undefined => {
  const layer = findShapeLayer(shapeName);

  if (!layer) {
    return undefined;
  }
  const layerIndex = getLayerIndex(layer);

  const layerPriority = findShapeDetail(shapeName)?.layerPriority;

  // If we have a layer proirity apply it to the layer index.
  return layerPriority ? layerPriority * layerIndex : layerIndex;
};

export const listLayerNames = (): readonly Layer[] =>
  Object.keys(LAYERS) as readonly Layer[];

export const getLayerDetail = (layer: Layer): LayerOption => {
  return LAYERS[layer];
};

export const getLayers = (): Layers => {
  return LAYERS;
};

export const getLayerName = (layer: Layer): string =>
  LAYERS[layer].name ?? layer;

export const getLayerDescription = (
  layer: Layer
): { readonly description?: string } => LAYERS_CONTEXT[layer];

/**
 * -1, a comes first
 * 0, no sorting
 * 1, b comes first
 */
type SortOrder = -1 | 0 | 1;

/**
 * Sort based on the layer indexes.
 */
const sortLayers = (aIndex: number, bIndex: number): SortOrder => {
  if (aIndex === bIndex) {
    return 0;
  }

  return aIndex > bIndex ? 1 : -1;
};

type Bounding = {
  readonly width?: number;
  readonly height?: number;
};

/**
 * Simular objects are of the same layer.
 *
 * This will compare width/height and sort lowest to highest
 */
const sortSimularLayers = (a: Bounding, b: Bounding): SortOrder => {
  if (!a.width || !a.height) {
    return 0;
  }

  // If there is invalid data, don't sort
  if (!b.width || !b.height) {
    return 0;
  }

  if (a.width + a.height == b.width + b.height) {
    return 0;
  }

  return a.width + a.height > b.width + b.height ? 1 : -1;
};

/**
 * Sort fabric.Canvas objects
 *
 * Accounts for how object should sort naturallly.
 */
export const sortCanvasObjects = (
  a: {
    readonly width?: number;
    readonly height?: number;
    readonly type?: string;
    readonly subtype?: string;
  },
  b: {
    readonly width?: number;
    readonly height?: number;
    readonly type?: string;
    readonly subtype?: string;
  }
): SortOrder => {
  // Invalid type to the back, but should not find this
  if (!a.type || !a.subtype) {
    return -1;
  }

  // Move invalid back
  if (!b.type || !b.subtype) {
    return 1;
  }

  // Invalid state
  if (!a.width || !a.height) {
    return -1;
  }

  // If there is invalid data, don't sort
  if (!b.width || !b.height) {
    return 1;
  }

  const aIndex = findShapeLayerIndex(a.subtype as Shape);
  const bIndex = findShapeLayerIndex(b.subtype as Shape);

  // Invalid layer index, back
  if (!aIndex) {
    return -1;
  }

  if (!bIndex) {
    return 1;
  }

  if (aIndex == bIndex) {
    return sortSimularLayers(a, b);
  }

  return sortLayers(aIndex, bIndex);
};

export type LayerState = { lock: boolean; visible: boolean };
export type LayersState = { readonly [K in Layer]?: LayerState };

export const toLayersState = (layers: Layers): LayersState =>
  Object.fromEntries(
    Object.entries(layers).map(([key, value]) => {
      return [key, toLayerState(value)];
    })
  ) as LayersState;

export const toLayerState = (value: LayerOption) => ({
  lock: value["lock"] ?? false,
  visible: value["visible"] ?? false,
});
